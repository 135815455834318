<template>
  <div class="writeProduct">
    <!-- 写文章 -->
    <div class="writeTop">
      <div class="left">
        <el-input placeholder="请输入文章标题" v-model="product.title" clearable size="small"></el-input>
        <el-input placeholder="请输入文章副标题" v-model="product.subTitle" clearable size="small">
        </el-input>
      </div>
      <div class="right">
        <el-button type="primary" size="small" v-if="isEdit" @click="updateStatus('draft')">保存草稿</el-button>
        <el-button type="primary" size="small" v-if="isEdit" @click="updateStatus('trash')">移至垃圾箱</el-button>
        <el-button type="primary" size="small" @click="submitProduct('normal')">发布</el-button>
      </div>
    </div>
    <div class="writeContent">
      <div class="left">
        <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
        <Editor style="height: 500px;max-width: 1160px;  overflow-y: hidden;" v-model="html" :defaultConfig="editorConfig"
          :mode="mode" @onCreated="onCreated" />
      </div>
      <div class="right">
        <el-collapse accordion>
          <el-collapse-item title="基础">
            <div class="item">
              <p>摘要</p>
              <el-input placeholder="请输入" v-model="product.summary" clearable size="small"></el-input>

            </div>
            <!-- <div class="item">
              <p>排序序号</p>
              <el-input placeholder="请输入" v-model="product.orderNumber" clearable type="number" size="small"></el-input>
              <span>文章列表会根据这个数值进行排序，越大越靠前。</span>
            </div> -->
            <div class="item">
              <p>购买链接</p>
              <el-input placeholder="请输入" v-model="product.buyLink" clearable size="small"></el-input>
            </div>
            <div class="item">
              <p>产品卖点</p>
              <el-input placeholder="请输入" v-model="product.usp" clearable size="small"></el-input>
            </div>
            <div class="item">
              <p>访问量</p>
              <el-input placeholder="请输入" v-model="product.viewCount" clearable size="small"></el-input>
            </div>
            <div class="item">
              <p>销量</p>
              <el-input placeholder="请输入" v-model="product.salesCount" clearable size="small"></el-input>
            </div>
            <!-- <div class="item">
              <p>发布时间</p>
              <el-date-picker v-model="product.modified" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
                placeholder="选择日期时间" size="small">
              </el-date-picker>
            </div> -->
          </el-collapse-item>
          <el-collapse-item title="价格">
            <div class="item">
              <p>销售价格</p>
              <el-input placeholder="请输入" type="number" v-model="product.price" clearable size="small"></el-input>
            </div>
            <div class="item">
              <p>原价</p>
              <el-input placeholder="请输入" type="number" v-model="product.originPrice" clearable size="small"></el-input>
            </div>
            <div class="item">
              <p>限时价格</p>
              <el-input placeholder="请输入" type="number" v-model="product.limitedPrice" clearable size="small"></el-input>
            </div>
          </el-collapse-item>
          <el-collapse-item title="分类">
            <div class="item">
              <el-tree :data="typeList" node-key="id" default-expand-all :expand-on-click-node="false">
                <span class="custom-tree-node" slot-scope="{ node, data }">
                  <el-checkbox v-model="data.select">{{ data.title }}</el-checkbox>
                  <!-- <span></span> -->
                </span>
              </el-tree>
            </div>
          </el-collapse-item>

          <el-collapse-item title="标签">
            <div class="item">
              <el-select v-model="tagValueList" multiple filterable allow-create default-first-option
                placeholder="请选择文章标签">
                <el-option v-for="item in tagList" :key="item.id" :label="item.title" :value="item.title">
                </el-option>
              </el-select>

              <span>多个标签请用 “回车键” 或英文逗号（,）隔开。</span>
            </div>
          </el-collapse-item>
          <el-collapse-item title="项目介绍标签">
            <div class="item">
              <el-button type="primary" size="mini" @click="addKey">添加/修改</el-button>
            </div>
            <div class="item" v-for="item in product.configurations">
              <p>{{ item.name }}</p>
              <!-- <p v-for="ele in item.value ">{{ ele}}</p> -->
            </div>
          </el-collapse-item>
          <el-collapse-item title="项目详请分类">
            <div class="item">
              <el-button type="primary" size="mini" @click="addKey2">添加/修改</el-button>
            </div>
            <div class="item" v-for="item in product.configurationsNew">
              <p>{{ item.name }}</p>
              <!-- <p v-for="ele in item.value ">{{ ele}}</p> -->
            </div>
          </el-collapse-item>
          <el-collapse-item title="产品封面">
            <div class="item">
              <el-upload class="avatar-uploader"
                :action="$store.state.requestAPI + `/attachment/upload?ct=${APIinfo.ct}&sign=${APIinfo.sign}&jpressAppId=${APIinfo.jpressAppId}`"
                :show-file-list="false" :data="{
                  'id': 0
                }" :on-success="handleAvatarSuccess">
                <img v-if="product.thumbnail" :src="$store.state.requestAPI + product.thumbnail" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </el-collapse-item>
          <el-collapse-item title="产品缩略图">
            <div class="item">
              <el-upload
                :action="$store.state.requestAPI + `/attachment/upload?ct=${APIinfo.ct}&sign=${APIinfo.sign}&jpressAppId=${APIinfo.jpressAppId}`"
                :data="{
                  'id': 0
                }" :on-success="handleAvatarSuccess2" list-type="picture-card" :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove" :file-list="fileList">
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
            </div>
          </el-collapse-item>
          <el-collapse-item title="SEO">
            <div class="item">
              <p>SEO标题</p>
              <el-input placeholder="请输入" v-model="product.metaTitle" clearable size="small"></el-input>
            </div>
            <div class="item">
              <p>SEO关键字</p>
              <el-input placeholder="请输入" v-model="product.metaKeywords" clearable size="small"></el-input>
            </div>
            <div class="item">
              <p>SEO描述</p>
              <el-input placeholder="请输入" v-model="product.metaDescription" clearable size="small"></el-input>
            </div>
          </el-collapse-item>
          <el-collapse-item title="状态" class="statusCollapse">
            <!-- <div class="item">
              <span>是否评论</span> <el-switch v-model="product.commentStatus">
              </el-switch>
            </div> -->
            <div class="item">
              <span>是否推荐</span> <el-switch v-model="product.withRecommend">
              </el-switch>
            </div>
            <div class="item">
              <span>是否置顶</span> <el-switch v-model="product.withTop">
              </el-switch>
            </div>
            <div class="item">
              <span>是否热门</span> <el-switch v-model="product.withHot">
              </el-switch>
            </div>
            <div class="item">
              <span>是否头条</span> <el-switch v-model="product.withLeadNews">
              </el-switch>
            </div>
            <div class="item">
              <span>是否允许被搜索</span> <el-switch v-model="product.withAllowSearch">
              </el-switch>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <AddKey ref="add" @getKey="getKey"></AddKey>
    <AddKey2 ref="add2" @getKey="getKey2"></AddKey2>
  </div>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { createProductAPI, productDetailAPI, updateProductAPI } from '@/api/product/product'
import { productTypeListAPI } from '@/api/product/type'
import { productTagsListAPI } from '@/api/product/tags'
import { basicSettingsAPI } from '@/api/settings/basic'
import AddKey from './AddKey.vue'
import AddKey2 from './AddKey2.vue'
export default {
  components: { Editor, Toolbar, AddKey, AddKey2 },
  data() {
    return {
      // 图片预览
      dialogImageUrl: '',
      configurationList: [
        { name: '项目优势', value: ['条件简单-无需投资、语言、年龄、无居住要求', '快速办理-排期最短，美国优先级最高', '一步到位-全家均可以一步到位同获绿卡'] },
        { name: '申请条件', value: ['无年龄限制', '符合杰出人才认定范畴', '由于其杰出成就获得过国际公认大奖的其他全国性或国际性奖励'] },
        { name: '申请条件', value: ['无年龄限制', '符合杰出人才认定范畴', '由于其杰出成就获得过国际公认大奖的其他全国性或国际性奖励'] },
        { name: '申请条件', value: ['无年龄限制', '符合杰出人才认定范畴', '由于其杰出成就获得过国际公认大奖的其他全国性或国际性奖励'] },
        { name: '申请条件', value: ['无年龄限制', '符合杰出人才认定范畴', '由于其杰出成就获得过国际公认大奖的其他全国性或国际性奖励'] },
        { name: '项目优势', value: ['条件简单-无需投资、语言、年龄、无居住要求', '快速办理-排期最短，美国优先级最高', '一步到位-全家均可以一步到位同获绿卡'] },
        { name: '申请条件', value: ['无年龄限制', '符合杰出人才认定范畴', '由于其杰出成就获得过国际公认大奖的其他全国性或国际性奖励'] },
        { name: '申请条件', value: ['无年龄限制', '符合杰出人才认定范畴', '由于其杰出成就获得过国际公认大奖的其他全国性或国际性奖励'] },
        { name: '申请条件', value: ['无年龄限制', '符合杰出人才认定范畴', '由于其杰出成就获得过国际公认大奖的其他全国性或国际性奖励'] },
        { name: '项目优势', value: ['条件简单-无需投资、语言、年龄、无居住要求', '快速办理-排期最短，美国优先级最高', '一步到位-全家均可以一步到位同获绿卡'] },
        { name: '申请条件', value: ['无年龄限制', '符合杰出人才认定范畴', '由于其杰出成就获得过国际公认大奖的其他全国性或国际性奖励'] },
        { name: '申请条件', value: ['无年龄限制', '符合杰出人才认定范畴', '由于其杰出成就获得过国际公认大奖的其他全国性或国际性奖励'] },
        { name: '申请条件', value: ['无年龄限制', '符合杰出人才认定范畴', '由于其杰出成就获得过国际公认大奖的其他全国性或国际性奖励'] },
      ],

      dialogVisible: false,
      fileList: [], //默认缩略图
      visible: false,
      defaultTypeList: [], // 之前选择的分类
      settingsInfo: {}, //基础设置的信息
      typeList: [], // 文章分类数组
      tagList: [], // 文章标签数组
      tagValueList: [], // 标签选中的数组
      selectList: [], // 选择的分类的id
      editor: null,
      siteInfo: null, // 修改固定链接
      html: '',
      text: '',
      toolbarConfig: {},
      APIinfo: {},
      editorConfig: {
        placeholder: '请输入内容...', MENU_CONF: {}
      },
      mode: 'default',// or 'simple'
      // 图片
      imageUrl: '',
      product: {
        text: '', // 纯文本内容
        configurations: [],
        configurationsNew: [], // 自定义字段2
        userId: null, // 用户id
        title: null,  // 标题
        subTitle: null, // 文章副标题
        content: null, //内容
        created: null, // 创建时间
        flag: null, // 标识，通常用于对某几篇文章进行标识，从而实现单独查询
        // id: null, // 主键ID
        buyLink: null, // 购买链接
        imgs: [], // 缩略图
        // list: [], // 文章集合
        metaDescription: null, //SEO描述信息
        metaKeywords: null, //SEO关键字
        metaTitle: null, // SEO标题
        // modified: null, // 最后更新时间
        tag: null, // 标签
        status: 'normal', // 状态
        summary: null, // 摘要
        thumbnail: null, // 产品封面
        viewCount: null, // 访问量
        salesCount: null, // 销售量
        usp: null, //产品卖点
        price: null, // 商品价格
        originPrice: null, //原始价格
        limitedPrice: null, //限时优惠价
        withAllowSearch: false, // 是否允许被搜索
        withHot: false, // 是否热门
        withLeadNews: false, // 是否头条
        withRecommend: false, // 是否推荐
        withTop: false //  是否置顶
      },
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      isEdit: false, // 新建还是修改


    }
  },
  methods: {
    // ----------------富文本编辑器
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      // this.text = this.editor.getText();

      // console.log(this.editorConfig)
    },
    // ----------------富文本编辑器------------------------

    // --------------上传图片-------------
    handleAvatarSuccess(res, file) {
      console.log(res)
      this.product.thumbnail = res.data.path;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    handleAvatarSuccess2(file) {
      // console.log(file)
      this.product.imgs = [file.data, ...this.product.imgs]
      console.log(this.product.imgs)
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.product.imgs = fileList.map(item => {
        return item.response.data;
      })
      // this.product.imgs = fileList.response.data;
      console.log(this.product.imgs)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // ----------------------上传图片 --------------

    // 递归取出选中的分类
    getSelectList(val) {
      if (val) {
        val.forEach(item => {
          if (item.select === true) {
            this.selectList.push(item.id);
          }
          if (item.children) {
            this.getSelectList(item.children)
          }
        })
      }
    },
    // 确定确定链接
    sureSite() {
      this.product.slug = this.siteInfo;
      // console.log(this.product)
      // this.$forceUpdate();
      this.visible = false;
    },
    //获取签名信息
    uploadFile() {
      this.$store.dispatch('getAPI');
      this.APIinfo = this.$store.state.APIinfo;
    },
    // 提交
    // async submitProduct() {
    //   // this.html = this.editor.getText();
    //   console.log(this.product)
    // },
    // // 提交
    async submitProduct() {
      // this.html = this.editor.getText();
      // console.log(this.editor.getText())
      if (this.product.title === null) {
        this.$message({
          showClose: true,
          message: '文章标题不能为空！'
        });
      } else if (this.html === null) {
        this.$message({
          showClose: true,
          message: '文章内容不能为空！'
        });
      } else {

        // console.log(this.editor.getText())
        this.product.text = this.editor.getText();
        this.getSelectList(this.typeList)
        this.product.userId = (JSON.parse(sessionStorage.getItem('user'))).id
        // console.log(this.selectList)
        this.product.tag = this.tagValueList.join(',')
        this.product.categorysId = [...new Set(this.selectList)];
        this.product.content = this.html;
        console.log(this.product)
        this.product.status = 'normal'
        if (this.product.imgs.length > 0) {
          let imgPath = this.product.imgs
          this.product.imgs = imgPath.map(item => {
            return item.path
          })
        }
        console.log(this.product)
        let res = {};
        // 判断是新增还是修改
        if (this.isEdit === false) {
          res = await createProductAPI(JSON.stringify(this.product));
          console.log(res);
        } else {
          res = await updateProductAPI(JSON.stringify(this.product));
          console.log(res);
        }

        if (res.code === 200) {
          this.$message({
            showClose: true,
            message: '操作成功！',
            type: 'success'
          });
          setTimeout(() => {
            this.html = ''
            this.tagValueList = [];
            this.selectList = [];
            this.defaultTypeList = [];
            this.product.categorysId = [];
            this.product = {
              text: '', // 纯文本内容
              configurations: [],
              userId: null, // 用户id
              title: null,  // 标题
              subTitle: null, // 文章副标题
              content: null, //内容
              created: null, // 创建时间
              flag: null, // 标识，通常用于对某几篇文章进行标识，从而实现单独查询
              // id: null, // 主键ID
              buyLink: null, // 购买链接
              imgs: [], // 缩略图
              // list: [], // 文章集合
              metaDescription: null, //SEO描述信息
              metaKeywords: null, //SEO关键字
              metaTitle: null, // SEO标题
              // modified: null, // 最后更新时间
              tag: null, // 标签
              status: 'normal', // 状态
              summary: null, // 摘要
              thumbnail: null, // 产品封面
              viewCount: null, // 访问量
              salesCount: null, // 销售量
              usp: null, //产品卖点
              price: null, // 商品价格
              originPrice: null, //原始价格
              limitedPrice: null, //限时优惠价
              withAllowSearch: false, // 是否允许被搜索
              withHot: false, // 是否热门
              withLeadNews: false, // 是否头条
              withRecommend: false, // 是否推荐
              withTop: false //  是否置顶

            }
            this.fileList = [];
            this.getTypeList();
            this.isEdit = false;
          }, 2000);
        } else {
          this.$message({
            showClose: true,
            message: '操作失败！' + res.msg,
            type: 'error'
          });
        }
      }
    },
    // 修改状态
    async updateStatus(val) {
      // let info = {id: this.$route.query.id, status: val}
      // let info = { ...this.product };
      this.product.text = this.editor.getText();
      this.getSelectList(this.typeList)
      this.product.userId = (JSON.parse(sessionStorage.getItem('user'))).id
      // console.log(this.selectList)
      this.product.tag = this.tagValueList.join(',')
      this.product.categorysId = [...new Set(this.selectList)];
      this.product.content = this.html;
      // console.log(this.product)
      // this.product.status = 'normal'
      if (this.product.imgs.length > 0) {
        let imgPath = this.product.imgs
        this.product.imgs = imgPath.map(item => {
          return item.path
        })
      }
      console.log(this.product)
      let info = this.product;
      delete info.tagsId;
      info.status = val;
      const res = await updateProductAPI(JSON.stringify(info));
      console.log(res);
      if (res.code === 200) {
        this.$message({
          showClose: true,
          message: '操作成功！',
          type: 'success'
        });
        setTimeout(() => {
            this.html = ''
            this.tagValueList = [];
            this.selectList = [];
            this.defaultTypeList = [];
            this.product.categorysId = [];
            this.fileList = [];
            this.product = {
              text: '', // 纯文本内容
              configurations: [],
              userId: null, // 用户id
              title: null,  // 标题
              subTitle: null, // 文章副标题
              content: null, //内容
              created: null, // 创建时间
              flag: null, // 标识，通常用于对某几篇文章进行标识，从而实现单独查询
              // id: null, // 主键ID
              buyLink: null, // 购买链接
              imgs: [], // 缩略图
              // list: [], // 文章集合
              metaDescription: null, //SEO描述信息
              metaKeywords: null, //SEO关键字
              metaTitle: null, // SEO标题
              // modified: null, // 最后更新时间
              tag: null, // 标签
              status: 'normal', // 状态
              summary: null, // 摘要
              thumbnail: null, // 产品封面
              viewCount: null, // 访问量
              salesCount: null, // 销售量
              usp: null, //产品卖点
              price: null, // 商品价格
              originPrice: null, //原始价格
              limitedPrice: null, //限时优惠价
              withAllowSearch: false, // 是否允许被搜索
              withHot: false, // 是否热门
              withLeadNews: false, // 是否头条
              withRecommend: false, // 是否推荐
              withTop: false //  是否置顶

            }
            this.getTypeList();
            this.isEdit = false;
          }, 2000);
      } else {
        this.$message({
          showClose: true,
          message: '操作失败！' + res.msg,
          type: 'error'
        });
      }
      // console.log(val);
      // let info = {}
    },
    // 获取文章分类列表
    async getTypeList() {
      const res = await productTypeListAPI({ pageNum: 1, pageSize: 10000 });
      // console.log(res);
      if (res.code === 200) {

        // 给分类数组添加是否选中状态，不能直接赋值，否则会导致选择框不能选中的问题
        let arr = res.data.list;
        this.addSelect(arr);
        let list = [];
        arr.forEach(ele => {
          list.push(ele)
        })
        this.typeList = list;
        this.defaultTypeList.forEach(item => {
          this.typeList.forEach(ele => {
            if (item === ele.id) {
              ele.select = true;
            }
            if (ele.children) {
              this.defaultTypeSelect(item, ele.children)
            }
          })
        })
      }
      console.log(this.typeList)
    },
    // 获取文章标签列表
    async getTagsList() {
      const res = await productTagsListAPI({ pageNum: 1, pageSize: 100000 });
      console.log('标签' + res);
      if (res.code === 200) {
        this.tagList = res.data.list;
      }
      // console.log(this.tagList)
    },
    // 递归增加是否选中的状态
    addSelect(val) {
      if (val.length > 0) {
        val.forEach(item => {
          item.select = false;
          if (item.children) {
            this.addSelect(item.children)
          }
        })
      }
    },
    // 获取文章详情
    async getDetail() {
      const res = await productDetailAPI({ id: this.$route.query.id });
      console.log(res);
      if (res.code === 200) {
        this.product = res.data;
        this.product.imgs = this.product.img.map(item => {
          return { path: item.src }
        })
        this.fileList = this.product.img.map(item => {
          return { url: this.$store.state.requestAPI + item.src }
        })
        //  this.product.img
        this.html = res.data.content;
        let tagsList = res.data.tagsId;
        // console.log(this.tagList)
        // console.log(tagsList)
        let tagsList1 = [];
        tagsList.forEach(item => {
          this.tagList.forEach(ele => {
            if (ele.id === item) {
              tagsList1.push(ele.title)
            }
          })
        })
        // console.log(tagsList1);
        this.tagValueList = tagsList1
        // 之前选择的分类
        this.defaultTypeList = res.data.categorysId;

      } else {
        this.$message({
          type: 'info',
          showClose: true,
          message: '详情获取失败!'
        });
      }
      // console.log(this.typeList)
    },
    // 默认分类选中
    defaultTypeSelect(val, val2) {
      val2.forEach(item => {
        if (val === item.id) {
          item.select = true;
        }
        if(item.children) {
          this.defaultTypeSelect(val, item.children)
        }
      })
    },
    // 获取设置详情
    async getSettingsDetail() {
      const res = await basicSettingsAPI();
      console.log(res);
      if (res.code === 200) {
        this.settingsInfo = res.data;
      } else {
        this.$message({
          type: 'info',
          showClose: true,
          message: '详情获取失败!'
        });
      }
    },
    // 自定义字段
    addKey() {
      let list = JSON.parse(JSON.stringify(this.product.configurations));
      let arr = []
      if (list.length > 0) {
        list.forEach((item, index) => {
          arr.push({ id: index + 1, name: item.name, values: item.values })
        })
      }
      // console.log(arr)
      this.$refs.add.open(JSON.stringify(arr));
    },
    // 自定义字段2
    addKey2() {
      let list = JSON.parse(JSON.stringify(this.product.configurationsNew));
      let arr = []
      if (list.length > 0) {
        list.forEach((item, index) => {
          arr.push({ id: index + 1, name: item.name, values: item.values })
        })
      }
      // console.log(arr)
      this.$refs.add2.open(JSON.stringify(arr));
    },
    // 获取修改后的自定义字段
    getKey(val) {
      // console.log(val)
      let list = val;
      let arr = [];
      list.forEach(item => {
        arr.push({ name: item.name, values: item.values })
      })
      console.log(arr);
      this.product.configurations = arr;
    },
    // 获取修改后的自定义字段
    getKey2(val) {
      // console.log(val)
      let list = val;
      let arr = [];
      list.forEach(item => {
        arr.push({ name: item.name, values: item.values })
      })
      // console.log(arr);
      this.product.configurationsNew = arr;
    }
  },

  mounted() {
    // console.log(this.editorConfig)

    // 模拟 ajax 请求，异步渲染编辑器
    // setTimeout(() => {
    //   this.html = ''
    // }, 1500)

  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  created() {
    this.getTagsList();
    if (this.$route.query.id) {
      this.isEdit = true;
      this.getDetail();
    }
    let requestAPI = this.$store.state.requestAPI;
    // console.log(this.editorConfig)
    setTimeout(() => {
      this.getTypeList();
      
    }, 500);

    this.uploadFile();
    this.getSettingsDetail();
    this.editorConfig.MENU_CONF['uploadImage'] = {
      server: `${this.$store.state.requestAPI}/attachment/upload?ct=${this.APIinfo.ct}&sign=${this.APIinfo.sign}&jpressAppId=${this.APIinfo.jpressAppId}`,
      meta: {
        id: 0
      },
      fieldName: 'file',
      // 单个文件上传失败
      onFailed(file, res) {   // TS 语法
        // onFailed(file, res) {           // JS 语法
        console.log(`${file.name} 上传失败`, res)
      },
      // 自定义插入图片
      customInsert(res, insertFn) {
        // console.log(res)
        // console.log(insertFn)
        // customInsert(res, insertFn) {                  // JS 语法
        // res 即服务端的返回结果

        // 从 res 中找到 url alt href ，然后插入图片
        insertFn(requestAPI + res.data.path, res.data.title, res.data.path)
      },

    }



  }
}
</script>
<!-- <style src="@wangeditor/editor/dist/css/style.css"></style> -->
<style lang="scss" scoped>
.writeProduct {
  height: 90%;

  .writeTop {
    display: flex;

    .left {
      width: 60%;

      .el-input {
        margin-bottom: 10px;
      }
    }

    .right {
      width: 50%;
      text-align: right;
    }
  }

  .writeContent {
    display: flex;
    width: 100%;
    height: 100%;

    .right {
      padding-left: 10px;
      border-left: 1px solid var(--border-color);
      width: 30%;
      height: 100%;
      overflow-y: scroll;

      .el-select {
        width: 100%;
      }

      .statusCollapse {
        .item {
          margin-bottom: 20px;

          span {
            display: inline-block;
            margin-right: 10px;
          }
        }
      }
    }
  }

  ::v-deep .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  ::v-deep .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  ::v-deep .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  ::v-deep .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>