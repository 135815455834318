<template>
  <div class="updatePass">
    <!-- 修改密码 -->
     <p class="title">修改密码</p>
            <div class="updatePassword" >

                <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px"
                    class="demo-ruleForm">
                    <el-form-item label="旧密码" prop="oldPass">
                        <el-input v-model.number="ruleForm.oldPass" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="pass">
                        <el-input type="password" size="small" v-model="ruleForm.pass" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码" prop="checkPass">
                        <el-input type="password" size="small" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
                    </el-form-item>
                    
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('ruleForm')" size="small">提交</el-button>
                    </el-form-item>
                </el-form>
            </div>
  </div>
</template>

<script>
import {  updateUserPassAPI } from '@/api/user/user'
export default {
  data() {
   
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'));
            } else {
                if (this.ruleForm.checkPass !== '') {
                    this.$refs.ruleForm.validateField('checkPass');
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.ruleForm.pass) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return{
          ruleForm: {
                pass: null,
                checkPass: null,
                oldPass: null
            },
            rules: {
                pass: [
                    { validator: validatePass, trigger: 'blur' }
                ],
                checkPass: [
                    { validator: validatePass2, trigger: 'blur' }
                ],
                oldPass: [
                { required: true, message: '请输入旧密码', trigger: 'blur' },
                ]
            },
        }
  },
  methods: {
    // 修改密码提交
    submitForm(formName) {
            this.$refs[formName].validate(async(valid) => {
                if (valid) {
                    // alert('submit!');
                    let info = {id: (JSON.parse(sessionStorage.getItem('user'))).id, password: this.ruleForm.checkPass, oldPassword: this.ruleForm.oldPass}
                    const  res = await updateUserPassAPI(JSON.stringify(info));
                    console.log(res);
                    if (res.code === 200) {
                        this.$message({
                            showClose: true,
                            message: '操作成功!当前账户已修改密码，请重新登录！',
                            type: 'success'
                        });
                        setTimeout(() => {
                          sessionStorage.clear();
                          this.$router.push('/admin/login')
                        }, 3000);
                    } else {
                        this.$message({
                            showClose: true,
                            message: '操作失败！' + res.msg,
                            type: 'error'
                        });
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
  }
}
</script>

<style lang="scss" scoped>
.updatePass{
  .title{
    font-weight: 600;
    text-align: center;
    margin: 30px 0px;
  }
  .updatePassword{
    width: 50%;
    margin: 0 auto;
  }
}

</style>