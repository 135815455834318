<template>
  <div>
    <!-- 自定义字段 -->
    <el-dialog :showClose="false" :visible.sync="dialogTableVisible"> 
        <!-- addKey -->
        <el-button type="text" @click="addKey">新建</el-button>
        <el-table height="300" :data="list" border>
            <el-table-column label="操作" width="80">
                <template slot-scope="scope">
                    <el-button type="text" @click="deleteKey(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
          <el-table-column  label="标题" width="200">
            <template slot-scope="scope">
            <el-input placeholder="请输入"  v-model="scope.row.name" clearable size="small"></el-input>
        </template>
          </el-table-column>
          <el-table-column  label="内容">
            <template slot-scope="scope">
              <el-select v-model="scope.row.values" multiple filterable allow-create default-first-option
                placeholder="请选择文章标签">
                <!-- <el-option v-for="item in tagList" :key="item.id" :label="item.title" :value="item.title">
                </el-option> -->
              </el-select>
              <!-- <span v-for="item in scope.row.value">{{ item }}</span> -->
            </template>
          </el-table-column>
        </el-table>
        <el-button type="primary" @click="submit">确定</el-button>
        <el-button type="primary" @click="dialogTableVisible = false">取消</el-button>
      </el-dialog>
  </div>
</template>

<script>

export default {
    data() {
        return{
            dialogTableVisible: false, // 自定义字段模态框
            list: [],
        }
    },
    methods: {
        open(val) {
            // console.log(val)
            this.list = JSON.parse(val);
           // console.log(this.list)
            this.dialogTableVisible = true;
        },
        deleteKey(val) {
           // console.log(val)
            this.list =  this.list.filter(item => {
                return item.id !== val
            })
           //  console.log(this.list)
        },
        addKey() {
          if(this.list.length > 0){
            let index = Number(this.list.length) - 1;
           // console.log(index)
            this.list.push({id: Number(this.list[index].id + 1) , name: '', values: []})
            // console.log(this.list)
          } else {
            this.list.push({id: 1 , name: '', values: []})
          }
          //console.log(this.list)
            
        },
        submit() {
        //    console.log(this.list);
           this.dialogTableVisible = false;
           this.$emit('getKey',this.list)
        }
    }
}
</script>

<style lang="scss" scoped>

    .el-select {
        width: 100%;
      }


</style>