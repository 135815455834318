import request from '@/utils/request'

import store from '@/store'
store.dispatch('getAPI');

// var info = store.state.APIinfo;
var info = JSON.parse(sessionStorage.getItem('APIinfo'));
// 获取商品标签列表
export function productTagsListAPI(params) {
    let newParam = {...params, ...info};
    return request({
      url: '/Product/findListProductTag',
      method: 'get',
      params: newParam
    });
}
// 新增商品标签
export function addProductTagsAPI(data) {
    return request({
        url: `/Product/addProductTag?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
        method: 'post',
        data
      });
}
// 修改商品标签
export function updateProductTagsAPI(data) {
    return request({
        url: `/Product/updateProductTag?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
        method: 'post',
        data
      });
}
// 删除文章标签
export function deleteProductTagsAPI(data) {
    return request({
        url: `/Product/deleteProductTag?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
        method: 'post',
        data
      });
}

// 文章标签详情
export function detailProductTagsAPI(params) {
    let newParam = {...params, ...info};
    return request({
      url: '/Product/findProductTagById',
      method: 'get',
      params: newParam
    });
}