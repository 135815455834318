import request from '@/utils/request'
// 获取文章列表
// console.log(store.state.APIinfo)
import store from '@/store'
store.dispatch('getAPI');

var info = JSON.parse(sessionStorage.getItem('APIinfo'));
// var info = {};
// setTimeout(() => {
//   info = store.state.APIinfo
// }, 100);
// console.log(info)

//获取文章列表
export function articleListAPI(data) {
    return request({
      url: `TCCArticle/findList?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
      method: 'post',
      data
    });
}
//全局搜索文章列表
export function searchArticleListAPI(data) {
  return request({
    url: `/TCCArticle/findGlobal?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
    method: 'post',
    data
  });
}

// 获取文章详情
export function articleDetailAPI(params) {
  let newParam = {...params, ...info};
  return request({
    url: '/TCCArticle/findArticleById',
    method: 'get',
    params: newParam
  });
}
// 新建文章
export function createArticleAPI(data) {
  return request({
    url: `/TCCArticle/doCreate?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
    method: 'post',
    data
  });
}
// 修改文章
export function updateArticleAPI(data) {
  return request({
    url: `/TCCArticle/updateArticle?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
    method: 'post',
    data
  });
}
// 删除文章
export function deleteArticleAPI(data) {
  return request({
    url: `/TCCArticle/deleteArticleList?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
    method: 'post',
    data
  });
}